/*
 * @Author: your name
 * @Date: 2021-11-11 15:46:38
 * @LastEditTime: 2022-02-23 11:06:13
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \do-auth-web\src\main.ts
 */
import { createApp } from 'vue';
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';
import App from './app.vue';
import { IUserManager, UserManager } from '@do/do-auth-web-client';
import { EnvConfig } from './env';
import { checkIfLogin } from './utils';
// AuthService.checkIfLogin();

(async () => {
    if (!(await checkIfLogin())) {
        const app = createApp(App);
        app.use(Antd).mount('#app');
    }
})();
