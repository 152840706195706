
import { message } from 'ant-design-vue';
import axios from 'axios';
import { Options, Vue } from 'vue-class-component';

@Options({
    props: ['visible'],
})
export default class Tryout extends Vue {
    formState = {
        companyName: '',
        userName: '',
        phone: '',
        email: '',
        description: '',
    };

    rules = {
        companyName: [
            { required: true, message: '请输入企业名称', trigger: ['change', 'blur'] },
            { pattern: /^[\u4e00-\u9fa5_ a-zA-Z0-9]+$/, message: '请输入正确格式', trigger: ['change', 'blur'] },
        ],
        userName: [
            { required: true, message: '请输入姓名', trigger: ['change', 'blur'] },
            { pattern: /^[\u4e00-\u9fa5_ a-zA-Z0-9]+$/, message: '请输入正确格式', trigger: ['change', 'blur'] },
        ],
        phone: [
            { required: true, message: '请输入手机号', trigger: ['change', 'blur'] },
            { pattern: /^1[0-9]{10}$/, message: '请输入正确格式', trigger: ['change', 'blur'] },
        ],
        email: [
            { required: true, message: '请输入邮箱', trigger: ['change', 'blur'] },
            {
                pattern: /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/,
                message: '请输入正确格式',
                trigger: ['change', 'blur'],
            },
        ],
        description: [{ required: true, message: '请简单描述您的需求、用途等', trigger: ['change', 'blur'] }],
    };

    private async _handleFinish(values: KV) {
        const {
            data: { code, msg },
        } = (await axios.post('https://api.designorder.cn/designBackend/doOperationBackend/collectUsers/saveUserInfo', values, {
            headers: { 'content-type': 'application/json;charset=UTF-8' },
        })) as ANY;
        if (code === 200) {
            message.success(msg);
            this.formState = {
                companyName: '',
                userName: '',
                phone: '',
                email: '',
                description: '',
            };
            (this.$refs.formRef as KV).clearValidate();
            this.$emit('close');
        }
    }
}
